import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`8:00 EMOM of:`}</p>
    <p>{`5- Back Squats (60% 1RM)`}</p>
    <p>{`5- Jump Squats (max height, not weighted)`}</p>
    <p>{`then,`}</p>
    <p>{`30-20-10 reps each of:`}</p>
    <p>{`Weighted Step Ups (30/20’s, 24/20″)`}</p>
    <p>{`GHD Situps`}</p>
    <p>{`Calorie Row`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free Partner WOD at 9:00 & 10:00am so bring a
friend!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      